import { gql } from 'generated/gql'

export const GET_SAVED_REPORTS_QUERY = gql(`
  query GetSavedReports($userId: ID!) {
    savedReports(userId: $userId) {
      ...SavedReportFields
    }
  }
`)

export const GET_SCHEDULED_EXPORTS_QUERY = gql(`
  query GetScheduledExports($userId: ID!) {
    scheduledExports(userId: $userId) {
      ...ScheduledExportFields
    }
  }
`)
