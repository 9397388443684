import { gql } from 'generated/gql'

export const CREATE_SAVED_REPORT_MUTATION = gql(`
  mutation CreateSavedReport(
    $name: String!
    $userId: ID!
    $reportConfig: SavedReportConfigInput!
  ) {
    mutationResult:createSavedReport (input: {
      name: $name
      userId: $userId
      reportConfig: $reportConfig
    }) {
      id
      errors
      savedReport {
        ...SavedReportFields
      }
    }
  }
`)

export const UPDATE_SAVED_REPORT_MUTATION = gql(`
  mutation UpdateSavedReport(
    $id: ID!
    $name: String
    $userId: ID
    $reportConfig: SavedReportConfigInput
  ) {
    mutationResult:updateSavedReport (input: {
      id: $id
      name: $name
      userId: $userId
      reportConfig: $reportConfig
    }) {
      id
      errors
      savedReport {
        ...SavedReportFields
      }
    }
  }
`)

export const DESTROY_SAVED_REPORT_MUTATION = gql(`
  mutation DestroySavedReport(
    $id: ID!
  ) {
    mutationResult:destroySavedReport (input: {
      id: $id
    }) {
      id
      errors
      savedReport {
        ...SavedReportFields
      }
    }
  }
`)

export const CREATE_SCHEDULED_EXPORT_MUTATION = gql(`
  mutation CreateScheduledExport(
    $dayOfMonth: Int
    $dayOfWeek: String
    $filename: String!
    $frequency: String!
    $recipients: [String!]!
    $savedReportId: ID!
    $timeOfDay: ISO8601DateTime!
    $weekCount: Int
  ) {
    mutationResult:createScheduledExport (input: {
      dayOfMonth: $dayOfMonth
      dayOfWeek: $dayOfWeek
      filename: $filename
      frequency: $frequency
      recipients: $recipients
      savedReportId: $savedReportId
      timeOfDay: $timeOfDay
      weekCount: $weekCount
    }) {
      id
      errors
      scheduledExport {
        ...ScheduledExportFields
      }
    }
  }
`)

export const UPDATE_SCHEDULED_EXPORT_MUTATION = gql(`
  mutation UpdateScheduledExport(
    $id: ID!
    $dayOfMonth: Int
    $dayOfWeek: String
    $filename: String!
    $frequency: String!
    $recipients: [String!]!
    $savedReportId: ID!
    $timeOfDay: ISO8601DateTime!
    $weekCount: Int
  ) {
    mutationResult:updateScheduledExport (input: {
      id: $id
      dayOfMonth: $dayOfMonth
      dayOfWeek: $dayOfWeek
      filename: $filename
      frequency: $frequency
      recipients: $recipients
      savedReportId: $savedReportId
      timeOfDay: $timeOfDay
      weekCount: $weekCount
    }) {
      id
      errors
      scheduledExport {
        ...ScheduledExportFields
      }
    }
  }
`)

export const DESTROY_SCHEDULED_EXPORT_MUTATION = gql(`
  mutation DestroyScheduledExport(
    $id: ID!
  ) {
    mutationResult:destroyScheduledExport (input: {
      id: $id
    }) {
      id
      errors
      scheduledExport {
        ...ScheduledExportFields
      }
    }
  }
`)
