import * as yup from 'yup';

export const scheduledExportSchema = yup.object().shape({
  id: yup.string().nullable(),
  filename: yup.string().required('File Name is required'),
  frequency: yup.string()
    .oneOf(['daily', 'workdays', 'weekly', 'monthly'])
    .required('Frequency is required'),
  weekCount: yup.number()
    .nullable()
    .when('frequency', {
      is: 'weekly',
      then: (schema) => schema.required('# Weeks is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  dayOfWeek: yup.string()
    .nullable()
    .oneOf([null, 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
    .when('frequency', {
      is: 'weekly',
      then: (schema) => schema.required('Day is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  dayOfMonth: yup.number()
    .nullable()
    .when('frequency', {
      is: 'monthly',
      then: (schema) => schema.required('# Day is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  timeOfDay: yup.date()
    .required('Time is required'),
  recipients: yup.array()
    .of(
      yup.string()
        .email('Each recipient must be a valid email address')
        .required('Email is required')
    )
    .required('Recipient(s) are required')
    .min(1, 'There must be at least one recipient'),
}).test(
  'invalid-combination',
  'If frequency is daily or workdays, weekCount, dayOfWeek, and dayOfMonth should not be set',
  function (value) {
    const { frequency, weekCount, dayOfWeek, dayOfMonth } = value;
    if (frequency && ['daily', 'workdays'].includes(frequency)) {
      return !weekCount && !dayOfWeek && !dayOfMonth;
    }
    return true;
  }
)
