import { CREATE_SAVED_REPORT_MUTATION, CREATE_SCHEDULED_EXPORT_MUTATION, DESTROY_SAVED_REPORT_MUTATION, DESTROY_SCHEDULED_EXPORT_MUTATION, UPDATE_SAVED_REPORT_MUTATION, UPDATE_SCHEDULED_EXPORT_MUTATION } from "./data/mutations"
import { GET_SCHEDULED_EXPORTS_QUERY } from "./data/queries"
import { scheduledExportSchema } from "./data/yupSchemas"
import IconButton from '@mui/material/IconButton';
import { useCallback, useReducer, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import COLORS from '../../lib/colors'
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { ordinalSuffix } from 'lib/utils';
import { capitalize, omit, range, sortBy, startCase } from 'lodash-es';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { InitializedRecord } from '@orbit/records'
import { useCurrentUser } from 'lib/CurrentUserProvider';
import Suspenseful from 'components/Suspenseful';
import MenuItem from '@mui/material/MenuItem';
import StandardDialog from 'components/UI/Dialog/StandardDialog';
import TextField from '@mui/material/TextField';
import { useNotifications } from 'lib/NotificationsProvider';
import { currentSettingsMatchSelectedReport } from './PerformanceTable';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';
import { serializeSavedReportConfig } from './serialization';
import { useTimezone } from 'lib/TimezoneProvider';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close'
import FormWrapper from 'components/Formik/FormWrapper';
import { FormSelect, FormTextField, useFormikContext } from 'components/Formik/forms';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import FreeTextMultiSelect from 'components/UI/FreeTextMultiSelect/FreeTextMultiSelect';
import Button from '@mui/material/Button';
import { ScheduledExport } from 'generated/graphql';


function SaveNewReportDialog({ onClose, setSelectedReport, tableSettings, setTableSettings, dateRange, refetch }) {
  const [reportName, setReportName] = useState(tableSettings.savedReportName || '')
  const { currentUser } = useCurrentUser()

  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()
  const [gqlSave] = useMutation(CREATE_SAVED_REPORT_MUTATION)
  const handleSave = useCallback(() => {
    setSubmitting(true)
    const mutation = {
      reportConfig: serializeSavedReportConfig(tableSettings, dateRange),
      name: reportName,
      userId: (currentUser as InitializedRecord).id,
    }

    gqlSave({ variables: mutation }).then((response) => {
      addNotification({variant: 'notice', message: `Saved "${reportName}".`})
      const savedReport = response?.data?.mutationResult.savedReport
      refetch()
      // Update query params to include the new saved report id and name
      setTableSettings(tableSettings => ({...tableSettings, savedReportName: savedReport?.name, savedReportId: savedReport?.id }))
      setSelectedReport(savedReport)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble creating "${reportName}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [tableSettings, dateRange, currentUser, reportName, gqlSave, addNotification, refetch, setTableSettings, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Save as New Report'
      description={<>Do you want to save the current <strong>filters</strong>, <strong>column arrangement</strong>, and <strong>table settings</strong> as a new Saved Report?</>}
      onCancel={onClose}
      onConfirm={handleSave}
      onClose={onClose}
      loading={submitting}
      disabled={reportName.length === 0}
      maxWidth='600px'
    >
      <TextField
        label={'Report Name'}
        value={reportName}
        onChange={(event) => setReportName(event.target.value)}
        fullWidth
        required
        sx={{ pr: 7 }}
      />
    </StandardDialog>
  )
}

function SaveExistingReportDialog({ onClose, setSelectedReport, tableSettings, dateRange, refetch }) {
  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()
  const [gqlUpdate] = useMutation(UPDATE_SAVED_REPORT_MUTATION)

  const handleUpdate = useCallback(() => {
    setSubmitting(true)
    const mutation = {
      id: tableSettings.savedReportId,
      reportConfig: serializeSavedReportConfig(tableSettings, dateRange)
    }

    gqlUpdate({ variables: mutation }).then((response) => {
      addNotification({variant: 'notice', message: `Saved "${tableSettings.savedReportName}".`})
      const savedReport = response?.data?.mutationResult.savedReport
      refetch()
      setSelectedReport(savedReport)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble saving "${mutation.name}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [tableSettings, dateRange, gqlUpdate, addNotification, refetch, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Save Report'
      description={<>Do you want to save <strong>{tableSettings.savedReportName}</strong> with the current <strong>filters</strong>, <strong>column arrangement</strong>, and <strong>table settings</strong>?</>}
      onCancel={onClose}
      onConfirm={handleUpdate}
      onClose={onClose}
      loading={submitting}
      maxWidth='600px'
    >
    </StandardDialog>
  )
}

export function ExportReportDialog({ onClose, selectedReport, tableSettings, handleExport }) {
  const [fileName, setFileName] = useState(tableSettings.savedReportName || selectedReport?.name || 'Performance')

  const handleConfirm = useCallback(() => {
    handleExport(fileName)
    onClose()
  }, [fileName, handleExport, onClose])

  return(
    <StandardDialog
      title='Export Performance Report'
      onCancel={onClose}
      onConfirm={handleConfirm}
      onClose={onClose}
      disabled={fileName.length === 0}
      maxWidth='600px'
    >
      <TextField
        label={'File Name'}
        value={fileName}
        onChange={(event) => setFileName(event.target.value)}
        fullWidth
        required
        sx={{ pr: 7 }}
      />
    </StandardDialog>
  )
}

function formatExportFrequency(scheduledExport) {

  switch(scheduledExport.frequency) {
    case 'daily':
      return 'Daily'
    case 'workdays':
      return 'Every Weekday'
    case 'weekly':
      if (scheduledExport.weekCount === 1) {
        return `Every Week on [${capitalize(scheduledExport.dayOfWeek.slice(0, 3))}] Day`
      } else {
        return `Every [${scheduledExport.weekCount}] Weeks on [${capitalize(scheduledExport.dayOfWeek.slice(0, 3))}] Day`
      }
    case 'monthly':
      return `Every [${ordinalSuffix(scheduledExport.dayOfMonth)}] Day of the Month`
  }

  return null
}

function ScheduledExportsList({sortedScheduledExports, openCreateOrUpdateScheduledExport, openDeleteScheduledExport}) {
  const { timezone } = useTimezone()

  return (
    <>
      { sortedScheduledExports.length === 0 &&
          <Typography variant="body2" sx={{ px: 4, color: COLORS.slateGray }}>
            No scheduled exports.
          </Typography>
      ||
        <>
          <Typography variant='h4'>
            Saved Scheduled Exports
          </Typography>
          <Stack>
            {sortedScheduledExports.map(scheduledExport => {
              const time = moment.tz(scheduledExport.timeOfDay, timezone).format('h:mm A')

              return (
                <Stack
                  direction='row'
                  onClick={() => openCreateOrUpdateScheduledExport(scheduledExport)}
                  key={scheduledExport.id}
                  sx={{
                    p: '12px 6px 12px 14px',
                    "&:hover": {
                      backgroundColor: `${COLORS.azuraWhite} !important`,
                    }
                  }}
                >
                  <Box sx={{width: '100%'}}>
                    <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>
                      <Typography variant='body1' sx={{width: '33%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {scheduledExport.savedReport.name}
                      </Typography>
                      <Typography variant='body1' align='right' sx={{width: '65%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {`${formatExportFrequency(scheduledExport)}, ${time}`}
                      </Typography>
                    </Stack>
                    <Typography variant="body2" color="info.main" align='right' sx={{width: '100%'}}>
                      {scheduledExport.recipients.join(', ')}
                    </Typography>
                  </Box>
                  <Box sx={{ml: 2, mt: '-8px'}}>
                    <IconButton aria-label='close' sx={{ color: COLORS.cadetGray }} onClick={(e) => {openDeleteScheduledExport(scheduledExport, 'scheduledExportList'); e.stopPropagation()}} size='small'>
                      <CloseIcon/>
                    </IconButton>
                  </Box>
                </Stack>
            )})}
          </Stack>
        </>
      }
    </>
  )
}

const FREQUENCY_OPTIONS = [
  {value: 'daily', text: 'Daily'},
  {value: 'workdays', text: 'Every Weekday'},
  {value: 'weekly', text: 'Every [X] Weeks on [X] Day'},
  {value: 'monthly', text: 'Every [X] Day of the Month'},
]

const DAY_OPTIONS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function ExportFrequencySelector() {
  const { values, setFieldValue } = useFormikContext<ScheduledExport>();
  const { timezone } = useTimezone()

  const handleFrequencySelect = useCallback((event) => {
    switch(event.target.value) {
      case 'daily':
      case 'workdays':
        setFieldValue('weekCount', null)
        setFieldValue('dayOfWeek', null)
        setFieldValue('dayOfMonth', null)
        break
      case 'weekly':
        setFieldValue('dayOfMonth', null)
        break
      case 'monthly':
        setFieldValue('weekCount', null)
        setFieldValue('dayOfWeek', null)
        break
    }

    setFieldValue('frequency', event.target.value)
  }, [setFieldValue])


  return (
    <Stack direction='row' gap={3}>
      <TextField
        select
        value={values.frequency || ''}
        onChange={handleFrequencySelect}
        label="Frequency"
        sx={{ width: 168 }}
        id='Frequency'
      >
        {FREQUENCY_OPTIONS.map(frequency => (
          <MenuItem value={frequency.value} key={frequency.value}>{frequency.text}</MenuItem>
        ))}
      </TextField>

      {values.frequency === 'weekly' &&
        <>
          <FormSelect
            label="# Weeks"
            name="weekCount"
            sx={{ width: 74 }}
          >
            {range(1, 5).map(value => (
              <MenuItem value={value} key={value}>{value}</MenuItem>
            ))}
          </FormSelect>
          <FormSelect
            label="Day"
            name="dayOfWeek"
            sx={{ width: 94 }}
          >
            {DAY_OPTIONS.map(day => (
              <MenuItem value={day} key={day}>{capitalize(day.slice(0, 3))}</MenuItem>
            ))}
          </FormSelect>
        </>
      }

      {values.frequency === 'monthly' &&
        <FormSelect
          label="# Day"
          name="dayOfMonth"
          sx={{ width: 94 }}
        >
          {range(1, 32).map(dayOfMonth => (
            <MenuItem value={dayOfMonth} key={dayOfMonth}>{ordinalSuffix(dayOfMonth)}</MenuItem>
          ))}
        </FormSelect>
      }

      <TimeField
        label="Time"
        value={values.timeOfDay ? moment.tz(values.timeOfDay, timezone): null}
        onChange={(newValue) => setFieldValue('timeOfDay', newValue)}
        format="hh:mm A"
        sx={{ width: 138}}
      />
    </Stack>
  )
}

function CreateOrUpdateScheduledExportForm() {
  const { values, setFieldValue } = useFormikContext<ScheduledExport>()
  const { timezone } = useTimezone()

  return (
    <Stack direction='column' gap={3}>
      <FormTextField
        label='File Name'
        name='filename'
        required
        sx={{ pr: 7 }}
      />

      <Box>
        <Typography variant='h4' mb={2}>
          Export Date(s)
        </Typography>
        <ExportFrequencySelector />
        <Typography color="info.main" variant="body2" mt={.5}>
          {`Export timezone is based on your organization (${timezone}).`}
        </Typography>
      </Box>

      <Box>
        <Typography variant='h4' mb={2}>
          Recipient(s)
        </Typography>
        <FreeTextMultiSelect
          label='Email(s)'
          value={values.recipients}
          setValue={(newValue) => setFieldValue('recipients', newValue)}
          required={true}
          sx={{ pr: 7 }}
        />
      </Box>
    </Stack>
  )
}

function CreateOrUpdateScheduledExportDialog({ onClose, selectedReport, isNew, onDelete }) {
  const { submitForm, isSubmitting } = useFormikContext()

  return(
    <StandardDialog
      title={`Schedule "${selectedReport.name}" Export`}
      onCancel={onClose}
      onConfirm={() => submitForm()}
      onClose={onClose}
      maxWidth='600px'
      disabled={isSubmitting}
      footer={<Button variant="outlined" onClick={onDelete} disabled={isNew}>Delete</Button>}
    >
      <CreateOrUpdateScheduledExportForm />
    </StandardDialog>
  )
}

function CreateOrUpdateScheduledExportContainer({ onClose, selectedReport, selectedExport, openDeleteScheduledExport }) {
  const { addNotification } = useNotifications()

  const isNew = !selectedExport?.id
  const [gqlSave] = useMutation(isNew ? CREATE_SCHEDULED_EXPORT_MUTATION : UPDATE_SCHEDULED_EXPORT_MUTATION)

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const mutation = values
    mutation.savedReportId = selectedExport?.savedReport?.id || selectedReport.id

    const actionText = isNew ? 'schedul' : 'updat'
    gqlSave({ variables: mutation }).then(() => {
      addNotification({variant: 'notice', message: `Your export has been ${actionText}ed`})
    }).catch(error => {
      addNotification({variant: 'alert', message: `There was an issue ${actionText}ing your export`})
      console.log(error)
    }).finally(() => {
      setSubmitting(false)
      onClose()
    })
  }, [addNotification, gqlSave, isNew, onClose, selectedExport?.savedReport?.id, selectedReport.id])

  return(
    <FormWrapper data={selectedExport} schema={scheduledExportSchema} onSubmit={handleSubmit}>
      <CreateOrUpdateScheduledExportDialog onClose={onClose} selectedReport={selectedReport} isNew={isNew} onDelete={() => openDeleteScheduledExport(selectedExport, 'createOrUpdateScheduledExport')} />
    </FormWrapper>
  )
}

function ScheduledExportsListContainer({ openCreateOrUpdateScheduledExport, openDeleteScheduledExport }) {
  const { currentUser } = useCurrentUser()
  const { data: { scheduledExports} } = useSuspenseQuery(GET_SCHEDULED_EXPORTS_QUERY, { variables: { userId: currentUser?.id}, fetchPolicy: "network-only" })
  const sortedScheduledExports = sortBy(scheduledExports, 'id')

  return (
    <Suspenseful
      component={ScheduledExportsList}
      sortedScheduledExports={sortedScheduledExports}
      openCreateOrUpdateScheduledExport={openCreateOrUpdateScheduledExport}
      openDeleteScheduledExport={openDeleteScheduledExport}
    />
  )
}

function ScheduledExportsDialog({ onClose, selectedReport, openCreateOrUpdateScheduledExport, openDeleteScheduledExport }) {

  return(
    <>
      <StandardDialog
        title='Scheduled Exports'
        description={<>All scheduled exports and their recipients are listed below.</>}
        onCancel={onClose}
        onConfirm={() => openCreateOrUpdateScheduledExport({filename: `${startCase(selectedReport.name).replace(/\s+/g, '')}.csv`})}
        disabled={!selectedReport}
        confirmText='Create New'
        tooltip={!selectedReport ? 'Selected a saved report to create new scheduled export' : ''}
        onClose={onClose}
        maxWidth='600px'
      >
        <ScheduledExportsListContainer openCreateOrUpdateScheduledExport={openCreateOrUpdateScheduledExport} openDeleteScheduledExport={openDeleteScheduledExport} />
      </StandardDialog>
    </>

  )
}

function RenameReportDialog({ onClose, selectedReport, setSelectedReport, setTableSettings, refetch }) {
  const [reportName, setReportName] = useState(selectedReport.name || '')

  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()

  const [gqlUpdate] = useMutation(UPDATE_SAVED_REPORT_MUTATION)

  const handleRename = useCallback(() => {
    setSubmitting(true)

    // Use selectedReport here to avoid updating properties besides the name (in case there are other changes presently)
    const mutation = {
      id: selectedReport.id,
      name: reportName
    }

    gqlUpdate({ variables: mutation }).then((response) => {
      addNotification({variant: 'notice', message: `Saved "${mutation.name}".`})
      const savedReport = response?.data?.mutationResult.savedReport
      refetch()
      // Update query params to include the new saved report id and name
      setTableSettings(tableSettings => ({...tableSettings, savedReportName: savedReport?.name, savedReportId: savedReport?.id }))
      setSelectedReport(savedReport)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble renaming "${selectedReport.name}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [selectedReport, reportName, gqlUpdate, addNotification, refetch, setTableSettings, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Rename Report'
      description={<>Do you want to rename <strong>{selectedReport.name}</strong>?</>}
      onCancel={onClose}
      onConfirm={handleRename}
      onClose={onClose}
      loading={submitting}
      disabled={reportName.length === 0 || reportName === selectedReport.name}
      maxWidth='600px'
    >
      <TextField
        label={'Report Name'}
        value={reportName}
        onChange={(event) => setReportName(event.target.value)}
        fullWidth
        required
        sx={{ pr: 7 }}
      />
    </StandardDialog>
  )
}

function DeleteReportDialog({ onClose, selectedReport, setSelectedReport, setTableSettings, refetch }) {
  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()

  const [gqlDestroy] = useMutation(DESTROY_SAVED_REPORT_MUTATION)

  const handleDelete = useCallback(() => {
    setSubmitting(true)

    const mutation = { id: selectedReport.id }

    gqlDestroy({ variables: mutation }).then((response) => {
      const savedReport = response?.data?.mutationResult.savedReport
      addNotification({variant: 'notice', message: `Deleted "${savedReport?.name}".`})
      refetch()
      // Remove saved report name and id from the query params
      setTableSettings(tableSettings => omit(tableSettings, ['savedReportId', 'savedReportName']))
      setSelectedReport(null)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble deleting "${selectedReport.name}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [selectedReport.id, selectedReport.name, gqlDestroy, addNotification, refetch, setTableSettings, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Delete Report'
      description={<>Do you want to delete <strong>{selectedReport.name}</strong>?</>}
      onCancel={onClose}
      confirmText={'Delete'}
      onConfirm={handleDelete}
      onClose={onClose}
      loading={submitting}
      maxWidth='600px'
    >
    </StandardDialog>
  )
}

function DeleteExportDialog({ onClose, onSuccess, selectedExport, unsetSelectedExport }) {
  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()

  const [gqlDestroy] = useMutation(DESTROY_SCHEDULED_EXPORT_MUTATION)

  const handleDelete = useCallback(() => {
    setSubmitting(true)

    const mutation = { id: selectedExport.id }

    gqlDestroy({ variables: mutation }).then(({ data }) => {
      const scheduledExport = data?.mutationResult.scheduledExport
      addNotification({variant: 'notice', message: `Deleted scheduled export of '${scheduledExport?.savedReport.name}'.`})
      unsetSelectedExport()
      onSuccess()
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble deleting scheduled export of '${selectedExport.name}'. Please try again later.`})
    }).finally(() => {
      setSubmitting(false)
    })
  }, [selectedExport.id, selectedExport.name, gqlDestroy, addNotification, unsetSelectedExport, onSuccess])

  return(
    <StandardDialog
      title='Delete Report'
      description={<>Do you want to delete the scheduled export of <strong>{selectedExport?.savedReport?.name}</strong>?</>}
      onCancel={onClose}
      confirmText={'Delete'}
      onConfirm={handleDelete}
      onClose={onClose}
      loading={submitting}
      maxWidth='600px'
    >
    </StandardDialog>
  )
}

const initialSavedReportMenuState = {
  saveNewReport: false,
  saveReport: false,
  renameReport: false,
  deleteReport: false,
  scheduledExportList: false,
  createOrUpdateScheduledExport: false,
  deleteExport: false,
  selectedExport: {},
  returnToModal: ''
}

function savedReportMenuReducer(state, action) {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        [action.payload]: true
      };
    case 'CLOSE':
      return {
        ...state,
        [action.payload]: false
      };
    case 'SET_SELECTED_EXPORT':
      return {
        ...state,
        selectedExport: action.payload
      };
    case 'SET_RETURN_TO_MODAL':
      return {
        ...state,
        returnToModal: action.payload
      };
    default:
      return state;
  }
}

export function SavedReportMenu({title, anchorEl, onClose, sidebarOpen, selectedReport, setSelectedReport, tableSettings, setTableSettings, dateRange, refetch, setExportOpen, revertToSelectedSavedReport}) {
  const [state, dispatch] = useReducer(savedReportMenuReducer, initialSavedReportMenuState)

  const openModal = (modalName, returnModalName='') => {
    dispatch({ type: 'OPEN', payload: modalName })
    dispatch({ type: 'SET_RETURN_TO_MODAL', payload: returnModalName })
    onClose()
  }
  const closeModal = (modalName, skipReturn=false) => {
    dispatch({ type: 'CLOSE', payload: modalName })
    state.returnToModal && !skipReturn && dispatch({ type: 'OPEN', payload: state.returnToModal })
    dispatch({ type: 'SET_RETURN_TO_MODAL', payload: '' })
  }
  const openCreateOrUpdateScheduledExport = (exportData) => {
    dispatch({ type: 'SET_SELECTED_EXPORT', payload: exportData })
    dispatch({ type: 'OPEN', payload: 'createOrUpdateScheduledExport' })
    dispatch({ type: 'CLOSE', payload: 'scheduledExportList' })
  }
  const openDeleteScheduledExport = (exportData, returnModalName) => {
    // close both paths to Delete Scheduled Export
    dispatch({ type: 'CLOSE', payload: 'scheduledExportList' })
    dispatch({ type: 'CLOSE', payload: 'createOrUpdateScheduledExport' })

    dispatch({ type: 'SET_SELECTED_EXPORT', payload: exportData })
    openModal('deleteExport', returnModalName)
  }

  const location = useLocation()
  const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`
  const { addNotification } = useNotifications()

  const savedReportHasChanges = !currentSettingsMatchSelectedReport(selectedReport, tableSettings, dateRange)

  return(
    <>
      <Menu
        id={`${title}-control-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{ sx:{ } }}
        anchorOrigin={{vertical: 0, horizontal: sidebarOpen ? 0 : 30}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: { width: 240, textOverflow: 'ellipsis', overflowX: 'hidden', overflowY: 'auto', whiteSpace: 'nowrap' },
        }}
      >
        <MenuItem onClick={() => openModal('saveNewReport')}>Save as New Report</MenuItem>
        <MenuItem onClick={() => openModal('saveReport')} disabled={!selectedReport || !savedReportHasChanges}>Save Report</MenuItem>
        <MenuItem onClick={() => {revertToSelectedSavedReport(); onClose()}} disabled={!selectedReport || !savedReportHasChanges}>Revert to Saved</MenuItem>
        <MenuItem onClick={() => {setExportOpen(true); onClose()}}>Export</MenuItem>
        <MenuItem onClick={() => openModal('scheduledExportList')}>Scheduled Exports</MenuItem>
        <CopyToClipboard text={fullUrl}>
          <MenuItem onClick={() => {addNotification({variant: 'notice', message: `Link for "${tableSettings.savedReportName}" has been copied`}); onClose()}} disabled={!selectedReport}>Share</MenuItem>
        </CopyToClipboard>
        <MenuItem onClick={() => openModal('renameReport')} disabled={!selectedReport}>Rename</MenuItem>
        <MenuItem onClick={() => openModal('deleteReport')} disabled={!selectedReport}>Delete</MenuItem>
      </Menu>

      {state.saveNewReport &&
        <SaveNewReportDialog onClose={() => closeModal('saveNewReport')} setSelectedReport={setSelectedReport} tableSettings={tableSettings} setTableSettings={setTableSettings} dateRange={dateRange} refetch={refetch} />
      }
      {state.saveReport &&
        <SaveExistingReportDialog onClose={() => closeModal('saveReport')} setSelectedReport={setSelectedReport} tableSettings={tableSettings} dateRange={dateRange} refetch={refetch} />
      }
      {state.scheduledExportList &&
        <ScheduledExportsDialog onClose={() => closeModal('scheduledExportList')} selectedReport={selectedReport} openCreateOrUpdateScheduledExport={openCreateOrUpdateScheduledExport} openDeleteScheduledExport={openDeleteScheduledExport} />
      }
      {state.createOrUpdateScheduledExport &&
        <CreateOrUpdateScheduledExportContainer onClose={() => {closeModal('createOrUpdateScheduledExport'); openModal('scheduledExportList')}} selectedReport={selectedReport} selectedExport={state.selectedExport} openDeleteScheduledExport={openDeleteScheduledExport} />
      }
      {state.renameReport &&
        <RenameReportDialog onClose={() => closeModal('renameReport')} selectedReport={selectedReport} setSelectedReport={setSelectedReport} setTableSettings={setTableSettings} refetch={refetch} />
      }
      {state.deleteReport &&
        <DeleteReportDialog onClose={() => closeModal('deleteReport')} selectedReport={selectedReport} setSelectedReport={setSelectedReport} setTableSettings={setTableSettings} refetch={refetch} />
      }
      {state.deleteExport &&
        <DeleteExportDialog onClose={() => closeModal('deleteExport')} onSuccess={() => closeModal('deleteExport', true)} selectedExport={state.selectedExport} unsetSelectedExport={() => dispatch({ type: 'SET_SELECTED_EXPORT', payload: {} })} />
      }
    </>
  )
}
